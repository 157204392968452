#mobile_menu:lang(fa) {
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 500;
}

#mobile_menu:lang(en) {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 500;
}

@media only screen and (max-width : 900px) {
  #mobile_menu:lang(fa) {
    top: 0;
  }
  #mobile_menu:lang(en) {
    top: 0;
  }
}
 
.hamburgerMenu {
  position: relative;
  width: 40px;
  height: 40px;
}

.hamburger_Button {
  cursor: pointer;
}

.hamburger_span {
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 2px;
  background: #FF3951;
  transition: all .4s;
  position: relative;
}

.hamburger_span + .hamburger_span {
  margin-top: 8px;
}

.active .hamburger_span:nth-child(1) {
  animation: ease .7s top forwards;
}

.not-active .hamburger_span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.active .hamburger_span:nth-child(2) {
  animation: ease .7s scaled forwards;
}

.not-active .hamburger_span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.active .hamburger_span:nth-child(3) {
  animation: ease .7s bottom forwards;
}

.not-active .hamburger_span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}
.iconSize svg{
  height: 18 !important;
  width: 18 !important;
}
@media only screen and (max-width : 600px) {
  .iconSize svg {
    height: 16 !important ;
    width: 16 !important;
  }
}
@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 11px;
    transform: rotate(0);
  }
  100% {
    top: 11px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 11px;
    transform: rotate(45deg);
  }
  50% {
    top: 11px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 10px;
    transform: rotate(0);
  }
  100% {
    bottom: 10px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 10px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 10px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
