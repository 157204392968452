.btnFeatures{
  animation: features 3s infinite;
}
@keyframes features {
  0%{
    box-shadow:#FF3951 0px 0px 15px;
  }
  50%{
    box-shadow:#FF3951 0px 0px 50px;
  }
  100%{
    box-shadow:#FF3951 0px 0px 15px;
  }
}
