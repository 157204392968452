.backImg{
  animation: backgroundRotate 20s infinite;
}
@keyframes backgroundRotate {
  50%{
    transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
    transform-style:preserve-3d;
    will-change:"transform";
  }
  100%{
    transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(360deg) skew(0deg, 0deg);
    transform-style:preserve-3d;
    will-change:"transform";
  }
}

@media (max-height:400px){
 .slogan{
  height: 50% !important;
 }
}