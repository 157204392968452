.loader-wrapper {
  width: 50px;
  height: 50px;
}
.loader{
  width: 100%;
  height: 100%;
  border: 3px solid transparent ;
  border-top-color: #FF3951;
  border-bottom-color: #BFCCE8 ;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

.loaderInner{
  border-top-color:#FF3951 ;
  border-bottom-color: #BFCCE8 ;
  animation-duration: 2.5s;
}

@keyframes rotate {
  0%{
    transform: scale(1) rotate(360deg);
  }
  50%{
    transform: scale(.5) rotate(-360deg);
  }
  100%{
    transform: scale(1) rotate(360deg);
  }
}
@media (max-width: 900px) {
  .loader-wrapper {
    width: 35px;
    height: 35px;
  }
}