body {
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: 'yekan', 'manrope',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x:hidden
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: 'yekan';
  src:  url(font/BYekan/BYekan.ttf),
        url(font/BYekan/BYekan.eot) format("eot"),
        url(font/BYekan/BYekan.woff) format("woff"),
        url(font/BYekan/BYekan.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'manrope';
  src: url(font/Manrope/Manrope-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'manrope';
  src: url(font/Manrope/Manrope-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}
@font-face{
  font-family: 'crimson';
  src: url(font/Crimson/CrimsonText-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}
@font-face{
  font-family: 'crimson';
  src: url(font/Crimson/CrimsonText-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}
@font-face{
  font-family: 'crimson';
  src: url(font/Crimson/CrimsonText-Italic.ttf);
  font-weight: normal;
  font-style: italic;
}
@font-face{
  font-family: 'crimson';
  src: url(font/Crimson/CrimsonText-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'crimson';
  src: url(font/Crimson/CrimsonText-SemiBold.ttf);
  font-weight: normal;
  font-style: normal;
}

*:lang(fa) {
  font-family: "yekan" !important;
}

*:lang(en) {
  font-family: "crimson" !important;
}

::-webkit-scrollbar {
  display: none;
}
