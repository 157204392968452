.clock{
  animation:clockAnime 5s infinite ;
}
@keyframes clockAnime{
  0%{
    transform:rotate(0deg)
  }
  100%{
    transform:rotate(180deg)
  }
}