.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperSorinMag .swiper-button-next:after{
  color:#000000;
  background-color: #EDEDED;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 25px;
  height: 25px;
  font-size: 15px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 800 !important;
}

.swiperSorinMag  .swiper-button-prev:after{
  color:#000000;
  background-color: #EDEDED;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 25px;
  height: 25px;
  font-size: 15px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 800 !important;
}

.swiperSample .swiper-button-prev{
  z-index:900 !important;
}

.swiperSample .swiper-button-next{
  z-index:900 !important;
}

.sliderSample{
  animation:silde 15s infinite ;
  animation-delay: 1s;
}

@keyframes silde{
  0%{
    transform:translateX(-390px);
  }
  25%{
    transform:translateX(400px);
  } 
  50%{
    transform:translateX(790px);
  } 
  75%{
    transform:translateX(1090px);
  } 
  100%{
    transform:translateX(1390px);
  }
}
.slideSwiper{
  background: transparent !important;
 
}

.swiper-slide-active{
  z-index: 300 ;
}
/* @media (max-width:1398px) and (min-width: 1328px){
  .sliderLoptop{
    min-width:640px !important;
    height:408px !important;
  }
}
@media (max-width:1328px) and (min-width: 1265px){
  .sliderLoptop{
    min-width:615px !important;
    height:390px !important;
  }
}
@media (max-width:1265px) and (min-width: 1240px){
  .sliderLoptop{
    min-width:590px !important;
    height:370px !important;
  }
}
@media (max-width:1240px) and (min-width: 1200px){
  .sliderLoptop{
    min-width:580px !important;
    height:353px !important;
  }
}
@media (max-width:1048px) and (min-width: 1028px){
  .sliderLoptop{
    min-width:152% !important;
    height:310px !important ;
  }
}
@media (max-width:1028px) and (min-width: 968px){
  .sliderLoptop{
    min-width:152% !important;
    height:300px !important ;
  }
}
@media (max-width:968px) and (min-width: 950px){
  .sliderLoptop{
    min-width:152% !important;
    height:285px !important ;
  }
}
@media (max-width:950px) and (min-width: 900px){
  .sliderLoptop{
    min-width:152% !important;
    height:280px !important ;
  }
}

@media (max-width:490px) {
  .mockapLoptop{
    left: 30px !important;
  }
}
@media (max-width:420px) {
  .mockapLoptop{
    left: 25px !important;
  }
}
@media (max-width:350px) {
  .mockapLoptop{
    left: 20px !important;
  }
} */

@media (max-width:780px) {
  .mockapMobile{
    right: 60px !important;
  }
}

@media (max-width:780px) {
  .mockapMobile{
    right: 60px !important;
  }
}
@media (max-width:720px) {
  .mockapMobile{
    right: 55px !important;
  }
}
@media (max-width:600px) {
  .mockapMobile{
    right: 64px !important;
  }
}
@media (max-width:570px) {
  .mockapMobile{
    right: 63px !important;
  }
}
@media (max-width:540px) {
  .mockapMobile{
    right: 55px !important;
  }
}
@media (max-width:480px) {
  .mockapMobile{
    right: 50px !important;
  }
}
@media (max-width:428px) {
  .mockapMobile{
    right: 48px !important;
  }
}
@media (max-width:385px) {
  .mockapMobile{
    right: 44px !important;
  }
}
@media (max-width:360px) {
  .mockapMobile{
    right: 40px !important;
  }
}
@media (max-width:340px) {
  .mockapMobile{
    right: 38px !important;
  }
}