.logoutHover:hover svg path{
    stroke:#FF3951;
}

.logoutHover:hover p{
  color:#FF3951;
}

.menuItem{
    font-size: 18px;
    color:#070707;
}

.menuItem:lang(fa){ 
  justify-content: flex-end !important;                       
}

.menuItem:lang(en){ 
  justify-content: flex-start !important;                       
}

.menuItem:focus{
  background-color: transparent !important;
  color:#070707 !important ;
}

.Mui-selected:focus{
  background-color: transparent !important;
  color:#FF3951 !important ;
}

.menuItem:hover{
  background-color: transparent !important;
}

.menuItem:active{
  background-color: transparent !important;
}

.menuItemTicket{
  font-size: 18px;
  color:#070707;
  background-color:#EAEAEA !important;
}

.menuItemTicket:lang(fa){ 
justify-content: flex-end !important;                       
}

.menuItemTicket:lang(en){ 
justify-content: flex-start !important;                       
}

.menuItemTicket:focus{
background-color:#EAEAEA !important;
color:#FF3951 !important ;
}

.menuItemTicket:hover{
background-color:#EAEAEA !important;
}

.menuItemTicket:active{
background-color:#EAEAEA !important;
}

.css-6fii2q-Arrow{
  display: none !important;
}

.colorStyleStatus{
  width: 30px !important;
  height: 20px !important;
}

.MuiList-root{
  padding: 0px !important;
}
.MuiPaper-root{
  border-radius: 10px !important;
}
.css-6a30wu-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: #FF3951 !important;
  display: none;
}
@media only screen and (max-width : 900px) {
  .menuItem{
    font-size: 14px;
  }
}

@media (max-width:1330px) and (min-width: 1200px) {
  .colorStyle{
    width: 45px !important;
  }
  
}
@media (max-width:1200px) and (min-width: 900px) {
  .colorStyle{
    width: 25px !important;
  }
}
@media (max-width:900px) and (min-width: 600px) {
  .colorStyle{
    width: 80px !important;
  }
  .css-cnfek5-Popover{
    left: -180px !important;
    top:20px !important
  }
}
@media (max-width:600px) and (min-width: 320px) {
  .colorStyle{
    width: 30px !important;
  }
  .inputColor1 .css-cnfek5-Popover{
    left: -210px !important;
    top:20px !important
  }
  .inputColor2 .css-cnfek5-Popover{
    left: -180px !important;
    top:20px !important
  }
  .inputColor3 .css-cnfek5-Popover{
    left: -130px !important;
    top:20px !important
  }
  .inputColor4 .css-cnfek5-Popover{
    left: -70px !important;
    top:20px !important
  }
  .inputColorStatus1 .css-cnfek5-Popover{
    left: -120px !important;
    top:20px !important
  }
  .inputColorStatus2 .css-cnfek5-Popover{
    left: -90px !important;
    top:20px !important
  }
  .inputColorStatus3 .css-cnfek5-Popover{
    left: -50px !important;
    top:20px !important
  }
  .inputColorStatus4 .css-cnfek5-Popover{
    left: -30px !important;
    top:20px !important
  }
  .colorStyleStatus{
    width: 18px !important;
  }
}

